// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query StatusGroupReport(
    $groupSlug: String!
    $dossierTypeSlug: String!
    $page: Int
    $first: Int!
    $sorters: [DataGridSorterInput]
  ) {
    list: statusGroupReport(
      groupSlug: $groupSlug
      dossierTypeSlug: $dossierTypeSlug
      page: $page
      first: $first
      sorters: $sorters
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        filters {
          name
          applied
          data
          metadata
          __typename
        }
        sorters {
          name
          applied
          direction
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { QUERY };
